/*********************
FONTS
*********************/
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@font-face {
  font-family: 'cotta';
  src: url('resources/cotta.woff2') format('woff2'),
       url('resources/cotta.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

$font-family-main:'Inter', sans-serif;
$font-family-heading:'cotta', sans-serif;

/*********************
BREAKPOINTS
*********************/

@mixin breakpoint($point) {
    @if $point == sm {
      @media (min-width: 544px) { @content ; }
   }
     @else if $point == md {
      @media (min-width: 850px) { @content ; }
   }
   @else if $point == lg {
    @media (min-width: 1024px) { @content ; } 
    }
    @else if $point == xl {
    @media (min-width: 1400px) { @content ; } 
    }
    @else if $point == xxl {
      @media (min-width: 1920px) { @content ; } 
    }
    @else if $point == xxxl {
      @media (min-width: 2200px) { @content ; } 
    }
 }



 /*********************
H-tags
*********************/

h1 {
  font-size: 40px;
  font-weight: 400;
  margin: 10px 0;
  font-family: $font-family-heading;
  color: #283A27;

  @include breakpoint(lg) {
    margin: 20px 0;
    font-size: 90px;
  }

  @include breakpoint(xxl) {
    font-size: 110px;
  }
}

h2 {
  font-size: 25px;
  font-weight: 400;
  margin: 10px 0;
  font-family: $font-family-heading;

  @include breakpoint(lg) {
    margin: 20px 0;
    font-size: 50px;
  }
}

h3 {
  font-size: 22px;
  font-weight: 400;
  margin: 10px 0;
  font-family: $font-family-main;

  @include breakpoint(lg) {
    margin: 20px 0;
    font-size: 30px;
  }
}




* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



body {
  background-color: #F4F2EF;
  font-family: $font-family-main;
  transition: background .3s ease-in-out;
  font-size: 18px;
  overscroll-behavior-y: none;

  @include breakpoint(lg) {
    font-size: 22px;
  }

  &.special {
    background-color: #283A27;
    color: white;

    h1 {
      color: white;
    }

    .nav-wrapper {
      color: white;

      a {
        color: white;
      }
  
      .burger span {
        background: white;
      }
    }
  }

  a {
    color: black;
  }
}

h1 {
  font-family: 'cotta', sans-serif;
}



.page-hero-wrapper {
  margin: 20vh auto;
  padding: 0 16px;
  max-width: 900px;
  text-align: center;
}


.header-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto 20vh auto;
  padding: 20vh 16px 0 16px;
  position: relative;


  .content {
    max-width: 900px;
  }

  .a1 {
    position: absolute;
    left: 3vw;
    top: -13vh;
    filter: blur(5px);
    z-index: -1;

    img {
      width: 80px;
    }

    @include breakpoint(lg) {
      left: 10vw;
      top: 11vh;

      img {
        width: 120px;
      }
    }
  }

  .a2 {
    position: absolute;
    right: 0;
    top: 30vh;
    z-index: -1;

    img {
      width: 120px;
    }

    @include breakpoint(lg) {
      position: absolute;
      right: 2vw;
      top: 27vh;

      img {
        width: 220px;
      }
    }
  }
  .a3 {
    position: absolute;
    left: 7vw;
    top: 90vh;
    filter: blur(5px);
    z-index: -1;
    
    img {
      width: 90px;
    }

    @include breakpoint(lg) {
      left: 35vw;
      top: 120vh;
    }
  }
}

.product-wrapper {
  width: 100%;
  margin: 20vh auto;
  padding: 0 16px;
  display: grid;
  gap: 8px;
  justify-content: center;
  position: relative;
  transition: .3s all;

  .focus {
    background-color: #D7EBD6;
    padding: 40px;
    border-radius: 12px;
  }

  .i1 {
    @include breakpoint(md) {
      position: absolute;
      right: 20%;
      top: 0;
      z-index: 10;
    }
  }

  .i2 {
    @include breakpoint(md) {
      position: absolute;
      left: 19%;
      top: 45%;
      z-index: 10;
    }
  }

  .i3 {
    @include breakpoint(md) {
      position: absolute;
      right: 23%;
      top: 85%;
      z-index: 10;
    }
  }
}

.glass {
  padding: 40px;
  border: 1px solid #c7d8c7;
  backdrop-filter: blur(10px);
  background: rgba(175, 213, 183, 0.1);
  border-radius: 12px;
  border: 1px solid rgba(162, 162, 162, 0.2);
}

// Gradient i topp
.top-gradient {
  opacity: 1;
  background: linear-gradient(#D7EBD6 0%, #eaefe700 100%);
  width: 100%;
  height: 1000px;
  position: absolute;
  top: 0;
  transform: translateY(-500px);
  z-index: -2;
}


.info-section-wrapper {
  color: white;
  transition: .3s all;
  margin: 20vh auto;

  .highlights {
    max-width: 1400px;
    margin: auto;
    padding: 0 16px;
    opacity: 0;
    transition: .3s all;

    .highlight {
      display: grid;
      grid-template-columns: 1fr;
      gap: 40px;
      align-items: center;
  
      @include breakpoint(md) {
        grid-template-columns: 1fr 1fr;
      }

      img, video {
        border-radius: 12px;
      }

      video {
        width: 100%;
        height: 600px;
        object-fit: cover;
      }

      .text {
        p {
          margin-bottom: 20px;
        }
      }
    }
  }


  .feedback {
    display: grid;
    grid-template-columns: 1fr;
    max-width: 1400px;
    margin: 20vh auto;
    gap: 8px;
    padding: 0 16px;

    @include breakpoint(md) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    .person {
      background-color: #384d37;
      padding: 20px;
      border-radius: 12px;
      display: grid;
      grid-template-columns: auto auto;
      gap: 20px;
      justify-content: center;
      align-items: center;

      img {
        width: 90px;
        height: 90px;
        border-radius: 200px;
      }
    }
  }

}

.nav-wrapper {
  position: fixed;
  top: 12px;
  z-index: 11;
  width: 100%;
  padding: 0 16px;

  .nav-content {
    max-width: 1400px;
    margin: 0 12px;
    margin: auto;
    padding: 8px 40px;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
    border-radius: 200px;
    position: relative;

    .logo {
      font-family: $font-family-heading;
      font-size: 26px;

      @include breakpoint(md) {
        font-size: 40px;
      }
    }

    .links {
      color: black;
      display: none;
      flex-direction: column;
      border-radius: 12px;
      padding: 20px;

      @include breakpoint(md) {
        flex-direction: row;
        gap: 40px;
        display: flex;
      }

      a {
          &:hover {
            @include breakpoint(md) {
              animation: jello;
              animation-duration: 1.2s;
            }
          }
      }
    }

    .menu-toggle {
      display: none;

      &:checked ~ .links {
        display: flex;
      }
    }

    .burger {
      
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 24px;
      height: 18px;
      cursor: pointer;

      span {
        display: block;
        height: 2px;
        background: black;
        border-radius: 2px;
      }

      @include breakpoint(md) {
        display: none;
      }
    }
  }
}






.product-summary {
  transition: .3s all;
  max-width: 1400px;
  margin: auto;
  color: white;
  padding: 0 16px;
  margin: 20vh auto;

  .products {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;

    @include breakpoint(md) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    .product {
      background-color: #384d37;
      border-radius: 12px;

      img {
        border-radius: 12px 12px 0 0;
      }

      .content {
        padding: 24px;
        text-align: center;

        h3 {
          margin: 0 0 24px 0;
        }
      }
    }
  }
}





*,
*::before,
*::after {
  box-sizing: border-box;
}


a {
  color: white;
  text-decoration: none;
}


.c-btn {
  position: relative;
  overflow: hidden;
  padding: 16px 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--color-primary);
  text-decoration: none;
  border-radius: 200px;
  background-color: transparent;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  box-shadow: inset 0 0 0 1px white;
  transform: translateZ(0);
}
.c-btn::after {
  content: "";
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 120%;
  width: 120%;
  border-radius: 20%;
  background-color: white;
  scale: 0 0;
  translate: 0 140%;
  transition: scale 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), translate 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.c-btn__label {
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  z-index: 2;
  font-size: 1.2rem;
  letter-spacing: 0.025em;
  transition: color 0.32s ease-in-out;
}

.c-btn:hover span {
  color: #283A27;
}
.c-btn:hover:after {
  scale: 1.5 1.5;
  translate: 0 0;
  border-radius: 50%;
}






.footer {
  color: #fff;
  align-items: center;
  width: 100%;
  max-width: 1400px;
  padding: 0 16px;
  margin: auto;
  display: flex;
  flex-direction: column;
  color: black;
  gap: 40px;

  a {
    text-decoration: underline;
  }

  hr {
    width: 100%;
    opacity: .2;
  }

  .partners {
    display: flex;
    max-width: 700px;
    margin: auto;
    padding: 0 16px;

    img {
      max-width: 100px;
    }
  }

  .disclaimer {
    max-width: 700px;
    margin: auto;
    text-align: center;
    opacity: .6;
    font-size: .8rem;
    padding: 0 16px 40px 16px;
  }

  &.light {
    color: white;

    a {
      color: white;
    }
  }

}


.show {
  opacity: 1 !important;
}
.hide {
  opacity: 0 !important;
}
img {
  width: 100%;
}