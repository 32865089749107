@import "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap";
@font-face {
  font-family: cotta;
  src: url("cotta.59cc9e78.woff2") format("woff2"), url("cotta.6aca7e69.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

h1 {
  color: #283a27;
  margin: 10px 0;
  font-family: cotta, sans-serif;
  font-size: 40px;
  font-weight: 400;
}

@media (width >= 1024px) {
  h1 {
    margin: 20px 0;
    font-size: 90px;
  }
}

@media (width >= 1920px) {
  h1 {
    font-size: 110px;
  }
}

h2 {
  margin: 10px 0;
  font-family: cotta, sans-serif;
  font-size: 25px;
  font-weight: 400;
}

@media (width >= 1024px) {
  h2 {
    margin: 20px 0;
    font-size: 50px;
  }
}

h3 {
  margin: 10px 0;
  font-family: Inter, sans-serif;
  font-size: 22px;
  font-weight: 400;
}

@media (width >= 1024px) {
  h3 {
    margin: 20px 0;
    font-size: 30px;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  overscroll-behavior-y: none;
  background-color: #f4f2ef;
  font-family: Inter, sans-serif;
  font-size: 18px;
  transition: background .3s ease-in-out;
}

@media (width >= 1024px) {
  body {
    font-size: 22px;
  }
}

body.special {
  color: #fff;
  background-color: #283a27;
}

body.special h1, body.special .nav-wrapper, body.special .nav-wrapper a {
  color: #fff;
}

body.special .nav-wrapper .burger span {
  background: #fff;
}

body a {
  color: #000;
}

h1 {
  font-family: cotta, sans-serif;
}

.page-hero-wrapper {
  text-align: center;
  max-width: 900px;
  margin: 20vh auto;
  padding: 0 16px;
}

.header-wrapper {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto 20vh;
  padding: 20vh 16px 0;
  display: flex;
  position: relative;
}

.header-wrapper .content {
  max-width: 900px;
}

.header-wrapper .a1 {
  filter: blur(5px);
  z-index: -1;
  position: absolute;
  top: -13vh;
  left: 3vw;
}

.header-wrapper .a1 img {
  width: 80px;
}

@media (width >= 1024px) {
  .header-wrapper .a1 {
    top: 11vh;
    left: 10vw;
  }

  .header-wrapper .a1 img {
    width: 120px;
  }
}

.header-wrapper .a2 {
  z-index: -1;
  position: absolute;
  top: 30vh;
  right: 0;
}

.header-wrapper .a2 img {
  width: 120px;
}

@media (width >= 1024px) {
  .header-wrapper .a2 {
    position: absolute;
    top: 27vh;
    right: 2vw;
  }

  .header-wrapper .a2 img {
    width: 220px;
  }
}

.header-wrapper .a3 {
  filter: blur(5px);
  z-index: -1;
  position: absolute;
  top: 90vh;
  left: 7vw;
}

.header-wrapper .a3 img {
  width: 90px;
}

@media (width >= 1024px) {
  .header-wrapper .a3 {
    top: 120vh;
    left: 35vw;
  }
}

.product-wrapper {
  justify-content: center;
  gap: 8px;
  width: 100%;
  margin: 20vh auto;
  padding: 0 16px;
  transition: all .3s;
  display: grid;
  position: relative;
}

.product-wrapper .focus {
  background-color: #d7ebd6;
  border-radius: 12px;
  padding: 40px;
}

@media (width >= 850px) {
  .product-wrapper .i1 {
    z-index: 10;
    position: absolute;
    top: 0;
    right: 20%;
  }

  .product-wrapper .i2 {
    z-index: 10;
    position: absolute;
    top: 45%;
    left: 19%;
  }

  .product-wrapper .i3 {
    z-index: 10;
    position: absolute;
    top: 85%;
    right: 23%;
  }
}

.glass {
  backdrop-filter: blur(10px);
  background: #afd5b71a;
  border: 1px solid #a2a2a233;
  border-radius: 12px;
  padding: 40px;
}

.top-gradient {
  opacity: 1;
  z-index: -2;
  background: linear-gradient(#d7ebd6 0%, #eaefe700 100%);
  width: 100%;
  height: 1000px;
  position: absolute;
  top: 0;
  transform: translateY(-500px);
}

.info-section-wrapper {
  color: #fff;
  margin: 20vh auto;
  transition: all .3s;
}

.info-section-wrapper .highlights {
  opacity: 0;
  max-width: 1400px;
  margin: auto;
  padding: 0 16px;
  transition: all .3s;
}

.info-section-wrapper .highlights .highlight {
  grid-template-columns: 1fr;
  align-items: center;
  gap: 40px;
  display: grid;
}

@media (width >= 850px) {
  .info-section-wrapper .highlights .highlight {
    grid-template-columns: 1fr 1fr;
  }
}

.info-section-wrapper .highlights .highlight img, .info-section-wrapper .highlights .highlight video {
  border-radius: 12px;
}

.info-section-wrapper .highlights .highlight video {
  object-fit: cover;
  width: 100%;
  height: 600px;
}

.info-section-wrapper .highlights .highlight .text p {
  margin-bottom: 20px;
}

.info-section-wrapper .feedback {
  grid-template-columns: 1fr;
  gap: 8px;
  max-width: 1400px;
  margin: 20vh auto;
  padding: 0 16px;
  display: grid;
}

@media (width >= 850px) {
  .info-section-wrapper .feedback {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.info-section-wrapper .feedback .person {
  background-color: #384d37;
  border-radius: 12px;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
  display: grid;
}

.info-section-wrapper .feedback .person img {
  border-radius: 200px;
  width: 90px;
  height: 90px;
}

.nav-wrapper {
  z-index: 11;
  width: 100%;
  padding: 0 16px;
  position: fixed;
  top: 12px;
}

.nav-wrapper .nav-content {
  border-radius: 200px;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: auto;
  padding: 8px 40px;
  display: grid;
  position: relative;
}

.nav-wrapper .nav-content .logo {
  font-family: cotta, sans-serif;
  font-size: 26px;
}

@media (width >= 850px) {
  .nav-wrapper .nav-content .logo {
    font-size: 40px;
  }
}

.nav-wrapper .nav-content .links {
  color: #000;
  border-radius: 12px;
  flex-direction: column;
  padding: 20px;
  display: none;
}

@media (width >= 850px) {
  .nav-wrapper .nav-content .links {
    flex-direction: row;
    gap: 40px;
    display: flex;
  }

  .nav-wrapper .nav-content .links a:hover {
    animation: 1.2s jello;
  }
}

.nav-wrapper .nav-content .menu-toggle {
  display: none;
}

.nav-wrapper .nav-content .menu-toggle:checked ~ .links {
  display: flex;
}

.nav-wrapper .nav-content .burger {
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  width: 24px;
  height: 18px;
  display: flex;
}

.nav-wrapper .nav-content .burger span {
  background: #000;
  border-radius: 2px;
  height: 2px;
  display: block;
}

@media (width >= 850px) {
  .nav-wrapper .nav-content .burger {
    display: none;
  }
}

.product-summary {
  color: #fff;
  max-width: 1400px;
  margin: 20vh auto;
  padding: 0 16px;
  transition: all .3s;
}

.product-summary .products {
  grid-template-columns: 1fr;
  gap: 24px;
  display: grid;
}

@media (width >= 850px) {
  .product-summary .products {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.product-summary .products .product {
  background-color: #384d37;
  border-radius: 12px;
}

.product-summary .products .product img {
  border-radius: 12px 12px 0 0;
}

.product-summary .products .product .content {
  text-align: center;
  padding: 24px;
}

.product-summary .products .product .content h3 {
  margin: 0 0 24px;
}

*, :before, :after {
  box-sizing: border-box;
}

a {
  color: #fff;
  text-decoration: none;
}

.c-btn {
  color: var(--color-primary);
  backface-visibility: hidden;
  background-color: #0000;
  border-radius: 200px;
  justify-content: center;
  align-items: center;
  padding: 16px 40px;
  text-decoration: none;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
  box-shadow: inset 0 0 0 1px #fff;
}

.c-btn:after {
  content: "";
  pointer-events: none;
  z-index: 1;
  background-color: #fff;
  border-radius: 20%;
  width: 120%;
  height: 120%;
  transition: scale .3s cubic-bezier(.215, .61, .355, 1), translate .4s cubic-bezier(.215, .61, .355, 1);
  position: absolute;
  top: 0;
  left: 0;
  translate: 0 140%;
  scale: 0;
}

.c-btn__label {
  z-index: 2;
  letter-spacing: .025em;
  align-items: center;
  gap: 1rem;
  font-size: 1.2rem;
  transition: color .32s ease-in-out;
  display: inline-flex;
}

.c-btn:hover span {
  color: #283a27;
}

.c-btn:hover:after {
  border-radius: 50%;
  translate: 0;
  scale: 1.5;
}

.footer {
  color: #000;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
  max-width: 1400px;
  margin: auto;
  padding: 0 16px;
  display: flex;
}

.footer a {
  text-decoration: underline;
}

.footer hr {
  opacity: .2;
  width: 100%;
}

.footer .partners {
  max-width: 700px;
  margin: auto;
  padding: 0 16px;
  display: flex;
}

.footer .partners img {
  max-width: 100px;
}

.footer .disclaimer {
  text-align: center;
  opacity: .6;
  max-width: 700px;
  margin: auto;
  padding: 0 16px 40px;
  font-size: .8rem;
}

.footer.light, .footer.light a {
  color: #fff;
}

.show {
  opacity: 1 !important;
}

.hide {
  opacity: 0 !important;
}

img {
  width: 100%;
}
/*# sourceMappingURL=repo.7424d5e2.css.map */
